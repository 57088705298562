import React, { Component } from 'react'
import Helmet from 'react-helmet'


class MetaTags extends Component {
    
    static defaultProps = {
        title: 'AQUA LUMA — MONA FOMA AT CATARACT GORGE — ROBIN FOX',
        description: "Hear Robin Fox’s new electronic composition, set to subharmonic frequencies and twelve-metre-high water jets erupting in the Gorge",
        //opengraph_image: 'https://sunsuper-nightsky.netlify.app'+require('../../assets/images/sunsuper-nightsky-skyline-2.jpg')
    }
 
    render() {

        const { 
            title, 
            description, 
            opengraph_description,
            opengraph_image,
            opengraph_title,
            twitter_description, 
            twitter_image,
            twitter_title,
        } = this.props;
		
        return (
            <Helmet
                title={title}
                meta={[
                    { name: 'description', content: description},
                    { property: 'og:image', content: opengraph_image },
                    { property: 'og:title', content: opengraph_title ? opengraph_title : title  },
                    { property: 'og:description', content: opengraph_description ? opengraph_description : description },
                    { property: 'twitter:card', content: 'summary' }, 
                    { property: 'twitter:image', content: twitter_image ? twitter_image : opengraph_image }, 
                    { property: 'twitter:title', content: twitter_title ? twitter_title : title },
                    { property: 'twitter:description', content: twitter_description ? twitter_description : description}
                ]}
            />
        )
    }
}

export default MetaTags