import React, { useState, useEffect, useRef, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import ReactPlayer from 'react-player'

import { PlayArrow, AccessTime, VolumeUp, VolumeOff } from '@material-ui/icons';

import { bgImage, bgIcon, colours, type } from '../../styles/global'
import { media, useBreakpoint, isClient } from '../../styles/utils'
import { formatDate, msToTime } from '../../utils'
import { usePlayer, playerContext } from './utils'

const Player = (props) => {
    const [renderAudio, audio] = usePlayer()
    const { status, setStatus, countdown, setCountdown } = useContext(playerContext)

    const resolveIconStatus = () => {
        const statusIcons = {
            begin: <PlayArrow className={'play-arrow'} />,
            wait: <AccessTime/>,
            starting: <AccessTime/>,
            playing: <VolumeUp/>,
            muted: <VolumeOff/>
        }

        return statusIcons[status]
    }

    const handleToggle = () => {
        if (status == 'begin') audio.play()
        if (status == 'playing') audio.toggleMute()
        if (status == 'muted') audio.toggleMute()
    }

    const renderControl = () => {
        if (status == 'finished') return;

        return (
            <Control>
                <Toggle
                    onClick={handleToggle}
                >
                    {resolveIconStatus()}
                </Toggle>
            </Control>
        )
    }

    const renderStatusText = () => {
        let statusText;

        if (status == 'begin') return (
            <Heading onClick={handleToggle}>
                Press to Begin
            </Heading>
        )

        if (status == 'wait') return (
            <Heading onClick={handleToggle}>
                Please Wait...
            </Heading>
        )

        if (status == 'starting') return (
            <Heading>
                Starting in
                <span>{countdown}</span> 
            </Heading>
        ) 

        if (status == 'playing') return (
            <Heading onClick={handleToggle}>
                Press to mute
            </Heading>
        )

        if (status == 'muted') return (
            <Heading onClick={handleToggle}>
                Audio muted
            </Heading>
        )

        if (status == 'finished') return (
            <Heading>
                <span>
                    This event is no longer available.<br/>
                    Thanks for listening
                </span>
            </Heading>
        )
    }

	return (
        <Wrapper>
            {renderControl()}
            {renderAudio()}

            <Status
                status={status}
            >
                {renderStatusText()}
            </Status>
        </Wrapper>
	)
}

// Shared

const Heading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
    margin-top: 48px;
`

// Control

const Control = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 465px;
    ${bgIcon}
    background-image: url(${require('../../assets/images/player-logo.svg')});

    ${media.phone`
        width: 226px;
        height: 339px;
    `}
`

const Toggle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        color: black;
        font-size: 64px;

        ${media.phone`
            font-size: 48px;
        `}

        &.play-arrow {
            font-size: 80px;

            ${media.phone`
                font-size: 64px;
            `}
        }
    }
`

// Status

const Status = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    min-height: 52px;

    ${media.phone`
        margin-top: 28px;
    `}

    ${Heading} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
        font-weight: 700;
        font-size: 19px;
        line-height: 1.34;

        span {
            color: white;
        }
    }

    /* Finished text */

    ${props => {
        if (props.status == 'finished') return css`
            margin-top: 115px;
            margin-bottom: 157px;
            min-height: initial;

            ${media.phone`
                margin-top: 115px;
                margin-bottom: 157px;
                min-height: initial;
            `}
        `
    }}
    
`





export default Player